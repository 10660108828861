/* 
.shwan-form .form-control */
.bookingFormUpdated .shwan-form .form-control {
  height: 40px;
  padding: 10px 16px;
}
.bookingFormUpdated .shwan-form select {
  height: 40px;
  padding: 1px 16px !important;
}
#butng {
  margin-top: -1rem;
}

.sigPad {
  width: 100%;
  height: 650px !important;
  background-color: #f7f7f7;
  border: 0.2px dashed #ded8d8;
  padding: 5px;
}

.sigImgWrapper {
  width: 100%;
  height: 100%;
  background-color: #f7f7f7;
  border: 0.2px dashed #ded8d8;
  padding: 5px;
}

.sigImage {
  background-size: 250px 150px;
  width: 250px;
  height: 125px;
  background-color: #f7f7f7;
}

.modal,
.modal-backdrop.show {
  z-index: 1101;
}

.modal.fullSizeModal .modal-dialog {
  max-width: 100% !important;
  max-height: 100% !important;
  height: 100% !important;
  margin: 0 !important;
}
.booking-form-box h5 {
  color: #3d3f43 !important;
  font-size: 22px !important;
  font-weight: 600;
  text-transform: none !important;
}
.table th {
  border-top: none;
}
.parking-table .table {
  border: 1px solid #dee2e6;
}
.button-nowrap {
  white-space: nowrap;
  width: '100%',  
}
/* Add custom styles to ensure proper field size */
.table .form-control {
  width: 100%; /* Ensure inputs expand to full width of column */
  min-width: 100px; /* Add a minimum width */
}

.table td input {
  text-align: right; /* Align numbers to the right */
}
.custom-email-container {
  gap: 2rem;
  display: flex; /* Default gap */
}

/* For medium screens (md) */
@media (min-width: 768px) {
  .custom-email-container {
    gap: 9rem; /* Adjust gap for medium screens */
    display: flex;
  }
}

/* For large screens (lg) */
@media (min-width: 992px) {
  .custom-email-container {
    gap: 9rem; /* Use a larger gap on large screens */
    display: flex;
  }
}

/* For extra large screens (xl) */
@media (min-width: 1200px) {
  .custom-email-container {
    gap: 7rem; /* Adjust the gap for extra large screens */
    display: flex;
  }
}
.broker-email-container {
  gap: 2rem;
  display: flex; /* Default gap */
}

/* For medium screens (md) */
@media (min-width: 768px) {
  .broker-email-container {
    gap: 9rem; /* Adjust gap for medium screens */
    display: flex;
  }
}

/* For large screens (lg) */
@media (min-width: 992px) {
  .broker-email-container {
    gap: 7.5rem; /* Use a larger gap on large screens */
    display: flex;
  }
}

/* For extra large screens (xl) */
@media (min-width: 1200px) {
  .broker-email-container {
    gap: 7rem; /* Adjust the gap for extra large screens */
    display: flex;
  }
}
.cs-table .form-control {
  width: 100%; /* Ensure inputs expand to full width of column */
  min-width: 50px; /* Add a minimum width */
}


/* Apply border to the whole table */
.custom-table-border {
  border-top: 1px solid #dee2e6;    /* Top border */
  border-bottom: 1px solid #dee2e6; /* Bottom border */
  border-left: 1px solid #dee2e6;   /* Left border */
  border-right: 1px solid #dee2e6;  /* Right border */
  border-collapse: collapse;     /* Collapse cell borders */
}



/* Optional: If you want a border at the bottom of the last row, add this */
.custom-table-border tbody tr:last-child td {
  border-bottom: 1px solid #dee2e6;
}
.linked-text {
  font-size: 22px;
  font-weight: 600;
  color: #3d3f43;
}