.table {
  background: #fff;
}
.table th {
  border-top: 1px solid #dee2e6 !important;
}
.table .tbl-header .sticky-header {
  background-color: #fff;
  top: 0;
  border-left: 0 !important;
  border-right: 0 !important;
  z-index: 1;
}
.tbl-header {
  border: none !important;
}
.table thead th {
  font-weight: bold;
  color: #000000;
}
.table thead:first-child tr th {
  padding: 15px 20px !important;
}
.table td {
  font-size: 16px;
  color: #212529;
}
.shwan-form .form-control {
  border-radius: 10px !important;
}
tbody td {
  padding: 8px 10px !important;
}
.table-hover-pointer tr:hover {
  cursor: pointer;
}
@media screen and (min-width: 600px) and (max-width: 1400px) {
  .new-page-bpdy-content {
    margin-top: 60px;
  }
}

.tablePaper {
  /* borderRadius: 6;
    height: 100vh; */
  height: calc(100vh - 248px);
  overflow: auto;
}
@media screen and (min-width: 600px) and (max-width: 1400px) {
  .tablePaper {
    /* borderRadius: 6;
       height: 100vh; */
    height: calc(100vh - 251px);
    overflow: auto;
  }
}
