body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.uploaderText {
  margin-left: 5px !important;
  color: #95a0ac;
  font-size: 18px !important;
}

.uploaderContainer {
  display: grid;
  place-content: center;
  place-items: center;
}

.ReactModal__Overlay--after-open {
  z-index: 1040 !important;
}

a {
  text-decoration: none !important;
}

@media screen and (min-width: 600px) and (max-width: 1400px) {
  .new-page-bpdy-content {
    margin-top: 35px;
  }

  .bookingApprovalBody table {
    font-size: 14px !important;
  }
}
