.table {
  background: #fff;
}
.table th {
  border-top: 1px solid #dee2e6 !important;
}
.table .tbl-header .sticky-header {
  background-color: #fff;
  top: 0;
  border-left: 0 !important;
  border-right: 0 !important;
  z-index: 1;
}
.tbl-header {
  border: none !important;
}
.table thead th {
  font-weight: bold;
  color: #000000;
}
.table thead:first-child tr th {
  padding: 15px 20px !important;
}
.table td {
  font-size: 16px;
  color: #212529;
}
tbody td {
  padding: 8px 10px !important;
}
.pagination-select .form-control:focus {
  box-shadow: none;
  border: 2px solid #4872f4;
}
@media screen and (min-width: 600px) and (max-width: 1400px) {
  .new-page-bpdy-content {
    margin-top: 60px;
  }
}
.suppDetailsHeader {
  display: flex;
}
.suppDetailsData {
  display: flex;
  gap: 10px;
  align-items: center;
}
.detailsHeaderWidth {
  width: 300px;
}
@media only screen and (max-width: 768px) {
  .suppDetailsData {
    width: 150px;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .preview-details {
    margin-left: 0 !important;
  }
  .suppDetailsHeader {
    gap: 0px;
  }
  .suppDetailsData {
    width: 150px;
  }
  .detailsHeaderWidth {
    width: 200px;
  }
  .preview-details:first-of-type {
    padding-right: 5px !important;
  }
  .preview-details:last-of-type {
    padding-left: 5px !important;
  }
}
