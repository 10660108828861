/* 
.shwan-form .form-control */
.bookingFormUpdated .shwan-form .form-control {
  height: 40px;
  padding: 10px 16px;
}
.bookingFormUpdated .shwan-form select {
  height: 40px;
  padding: 1px 16px !important;
}
#butng {
  margin-top: -1rem;
}

.sigPad {
  width: 100%;
  height: 650px !important;
  background-color: #f7f7f7;
  border: 0.2px dashed #ded8d8;
  padding: 5px;
}

.sigImgWrapper {
  width: 100%;
  height: 100%;
  background-color: #f7f7f7;
  border: 0.2px dashed #ded8d8;
  padding: 5px;
}

.sigImage {
  background-size: 250px 150px;
  width: 250px;
  height: 125px;
  background-color: #f7f7f7;
}

.modal,
.modal-backdrop.show {
  z-index: 1101;
}

.modal.fullSizeModal .modal-body {
  padding: 0.5rem;
}
.modal.fullSizeModal .modal-dialog {
  max-width: 100% !important;
  max-height: 100% !important;
  height: 100% !important;
  margin: 10px 0 0 0 !important;
}
.booking-form-box h5 {
  color: #3d3f43 !important;
  font-size: 22px !important;
  font-weight: 600;
  text-transform: none !important;
}
.parking-table .table {
  border: 1px solid #dee2e6;
}
