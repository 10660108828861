.sales-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sales-grid-container {
  display: grid;
  grid-template-columns: 2fr 2fr;
  gap: 20px;
}
.table-container {
  height: 100vh;
  max-height: calc(100vh - 400px);
  overflow: auto;
}
.table-container thead {
  background-color: #f6f8fe;
  border-bottom: 1px solid #e3e6e8;
  height: 57px;
  position: sticky;
  top: 0;
  z-index: 1;
}
.table-container th {
  padding: 8px 22px;
  color: #041d36;
  font-size: 18px;
  font-weight: 400;
  line-height: 24.55px;
}
.table-container th:first-child {
  width: 808px;
}

.table-container th:nth-child(n + 2) {
  width: 310px;
}
.table-container tbody tr {
  height: 57px;
}
.table-container td {
  padding: 8px 22px;
  color: #041d36;
  font-size: 18px;
  font-weight: 400;
  line-height: 24.55px;
  border-bottom: 1px solid #e3e6e8;
}

.sales-btn {
  font-family: 'nunitosemibold';
  border-radius: 12px;
  padding: 5px 20px;
  line-height: 22px;
  font-size: 16px;
  transition: all 0.5s;
  -moz-box-shadow: 0px 0px 0px rgba(68, 68, 68, 0.1);
  -webkit-box-shadow: 0px 0px 0px rgba(68, 68, 68, 0.1);
  box-shadow: 0px 0px 0px rgba(68, 68, 68, 0.1);
  background-color: rgba(72, 114, 244, 0.1);
  border: 1px solid transparent;
  color: #4872f4;
}
.sales-btn:hover {
  color: #4872f4;
  -moz-box-shadow: 0px 6px 6px rgba(68, 68, 68, 0.4);
  -webkit-box-shadow: 0px 6px 6px rgba(68, 68, 68, 0.4);
  box-shadow: 0px 6px 6px rgba(68, 68, 68, 0.4);
}
.sales-btn:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.category-table {
  font-weight: 400;
  font-size: 18px;
  color: rgba(4, 29, 54, 0.5);
  border-top: 1px solid rgba(4, 29, 54, 0.2) !important;
  border-bottom: 1px solid rgba(4, 29, 54, 0.2) !important;
}
.category-table td {
  color: #212529;
}
.selected {
  background-color: #4872f4;
  color: white;
}
.selected:hover {
  color: #fff;
}
