/* 
.shwan-form .form-control */
.bookingFormUpdated .shwan-form .form-control {
  height: 40px;
  padding: 10px 16px;
}
.bookingFormUpdated .shwan-form select {
  height: 40px;
  padding: 1px 16px !important;
}
#butng {
  margin-top: -1rem;
}

.sigPad {
  width: 100%;
  height: 300px;
  background-color: #f7f7f7;
  border: 0.2px dashed #ded8d8;
  padding: 5px;
}

.sigImgWrapper {
  width: 100%;
  height: 100%;
  background-color: #f7f7f7;
  border: 0.2px dashed #ded8d8;
  padding: 5px;
}

.sigImage {
  background-size: 250px 150px;
  width: 250px;
  height: 125px;
  background-color: #f7f7f7;
}

.modal,
.modal-backdrop.show {
  z-index: 1101;
}
.units-booking-form-box {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(4, 29, 54, 0.06);
  border-radius: 5px;
  /* display: flex; */
  flex-wrap: wrap;
  width: 100%;
  padding: 12px 32px !important;
}
.units-booking-form-box .booking-form-col-12 {
  padding: 0 !important;
  gap: 14px;
}
.units-booking-form-box h5 {
  font-size: 18px;
  font-weight: 600;
  line-height: 25.55px;
  color: #3d3f43;
}
.units-booking-form-box .table thead th {
  border-bottom: 2px solid #c3c3c3;
}
.units-booking-form-box .table th {
  border-top: none;
  font-size: 15px;
  font-weight: 500;
  color: #041d36;
}
.units-booking-form-box .table td {
  border-top: none;
}
.units-booking-form-box .booking-form-col-6 {
  padding: 0 !important;
  gap: 14px;
}

.total-background {
  background-color: #edf1fe;
  padding: 5px 12px 5px 22px;
  border-radius: 4px;
}
.units-booking-form-box .form-control {
  border-radius: 0 !important;
  border: 1px solid rgba(4, 29, 54, 0.1);
}
.card-booking-form-box {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(4, 29, 54, 0.06);
  border-radius: 5px;
  /* display: flex; */
  /* flex-wrap: wrap; */
  width: 800px;
  padding: 12px 32px !important;
}
.card-booking-form-box .Btn {
  padding: 10px 15px;
  font-size: 15px;
}
@media print {
  /* body {
    font-family: Arial, sans-serif;
    margin: 0;
  } */

  #pdf-content {
    width: 100%;
    margin: 0;
    padding: 20px;
  }
  
  .btn {
    display: none; /* Hide buttons in the PDF */
  }
}

/* For medium screens (md) */
@media (min-width: 768px) {
  .unit-terrace {
    gap: 9rem; /* Adjust gap for medium screens */
    display: flex;
  }
}

/* For large screens (lg) */
@media (min-width: 992px) {
  .unit-terrace {
    gap: 6.8rem; /* Use a larger gap on large screens */
    display: flex;
  }
}

/* For extra large screens (xl) */
@media (min-width: 1200px) {
  .unit-terrace {
    gap: 5.5rem; /* Adjust the gap for extra large screens */
    display: flex;
  }
}
@media (min-width: 768px) {
  .unit-parking {
    gap: 9rem; /* Adjust gap for medium screens */
    display: flex;
  }
}

/* For large screens (lg) */
@media (min-width: 992px) {
  .unit-parking {
    gap: 7.1rem; /* Use a larger gap on large screens */
    display: flex;
  }
}

/* For extra large screens (xl) */
@media (min-width: 1200px) {
  .unit-parking {
    gap: 5.5rem; /* Adjust the gap for extra large screens */
    display: flex;
  }
}
.page-break {
  page-break-before: always;
}
